@import '../utils/variables.scss';

.maintenance {
    height: 100vh;
    background-color: $c-secondary;

    &__message{
        background-color: white;
        max-width: 700px;
        width: 100%;
        border-radius: 1.25rem;
        margin: 0 20px;
    }

    &__logo {
        max-width: 350px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    &__text {
        font-size: 30px;
        font-weight: 500;
        color: $c-text;
        margin-bottom: 30px;
    }
}