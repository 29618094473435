/*------------------------------------*\
  Transversal Elements
  #Styles variables
\*------------------------------------*/

/* Variables de colores */

$c-text: #373435;

$c-primary: #fc2930;
$c-secondary: rgba(210, 211, 213, 0.2);