@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.maintenance{height:100vh;background-color:rgba(210,211,213,0.2)}.maintenance__message{background-color:white;max-width:700px;width:100%;border-radius:1.25rem;margin:0 20px}.maintenance__logo{max-width:350px;width:100%;margin-top:30px;margin-bottom:20px}.maintenance__text{font-size:30px;font-weight:500;color:#373435;margin-bottom:30px}

body{font-family:'Roboto', sans-serif}

